.sidebars {
    @apply absolute top-0 w-8 h-full bg-transparent z-10 flex items-end text-white mix-blend-difference;
}

.sidebars > div {
    @apply sticky bottom-0 flex flex-col justify-end items-center gap-y-2.5;
}

.sidebars > div > div {
    @apply border-r-2  rounded-full w-0.5 border-white;
}

.rightSidebar > div a,
.rightSidebar > div span {
    @apply [writing-mode:vertical-lr]
}
