.projectCard {
    @apply items-center justify-center
    absolute top-0 left-0 w-full h-full
    bg-opacity-0 bg-[#181818] hidden
    group-hover:flex group-hover:bg-opacity-80
    transition-all duration-500;
}

.links {
    @apply h-14 w-14 mr-2 border-2 relative
    rounded-full border-[#ADB7BE] hover:border-white;
}

.icons {
    @apply h-10 w-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
    text-[#ADB7BE] cursor-pointer group-hover/link:text-white;
}